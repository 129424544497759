import React from 'react'
import styled from 'styled-components'
import RadioField from '@/v2/components/common/RadioField'
import PatientForm from '@/v2/components/forms/Patient/Form'
import SelectedPatient from '@/v2/components/common/SelectedPatient'
import PatientSearch from '@/v2/components/common/PatientSearch'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`

const PatientSelection = ({
  patientType,
  registeredPatient,
  newPatient,
  error,
  canRemove = false,
  onChange,
}) => {
  return (
    <Wrapper>
      {canRemove && (
        <RadioField
          color="secondary"
          value={patientType}
          options={[
            { label: 'Returning Patient', value: 'registered' },
            { label: 'New Patient', value: 'new' },
          ]}
          onChange={value => onChange({ patientType: value })}
        />
      )}
      {patientType === 'new' && (
        <PatientForm
          values={newPatient}
          error={error.newPatient}
          onChange={value => onChange({ newPatient: { ...value } })}
        />
      )}
      {patientType === 'registered' && !registeredPatient.id && (
        <PatientSearch
          autoFocus
          showSearchIcon
          error={error.registeredPatient}
          onChange={patient =>
            onChange({
              registeredPatient: patient,
              contactMethod: patient.contactMethod,
            })
          }
        />
      )}
      {registeredPatient.id && (
        <SelectedPatient
          registeredPatient={registeredPatient}
          canRemove={canRemove}
          onChange={() => onChange({ registeredPatient: {} })}
        />
      )}
    </Wrapper>
  )
}

PatientSelection.defaultProps = {
  canRemove: true,
}

export default PatientSelection
