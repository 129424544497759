import React from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { format } from 'date-fns'
import { useTheme } from '@mui/material/styles'
import MoreIcon from '@mui/icons-material/MoreVert'
import { TIME_IN_US_FORMAT } from '@/v2/constants'
import Typography from '@/v2/components/common/Typography'
import IconMenu from '@/v2/components/common/IconMenu'
import { usePatientContext } from '@/v2/contexts/PatientContext'
import { useSnackbarContext } from '@/v2/contexts/SnackbarContext'
import useModals from '@/v2/hooks/useModals'
import { useDeleteTreatmentNote } from '@/v2/hooks/treatments/useTreatmentNotes'
import { getDateTimeWithoutTimezone } from '@/v2/utils/convert'
import { parseHtmlToString } from '@/v2/utils/helpers'

const Item = styled.div`
  display: flex;
  align-items: center;
  padding: 24px 26px;
  margin-top: 15px;
  border-radius: 3px;
  background: ${({ theme, classification }) => theme[classification].note};
`
const DateTime = styled.div`
  align-self: baseline;
  margin-right: 56px;
  min-width: 114px;
`
const Title = styled.div`
  flex: 1;
`

const TreatmentNote = ({ note }) => {
  const theme = useTheme()
  const { goTo } = useModals()
  const { id: patientId } = useParams()
  const { onUpdateNote, onDeleteNote } = usePatientContext()
  const { onOpenSnackbar } = useSnackbarContext()
  const { id, classification, annotation, createdAt, user } = note
  const { deleteTreatmentNote } = useDeleteTreatmentNote(patientId, id)
  const date = getDateTimeWithoutTimezone(createdAt)

  const handleConfirmDeletion = () => {
    confirm('Are you sure that you want to delete this note?') && handleDelete()
  }

  const handleDelete = async () => {
    try {
      const { error } = await deleteTreatmentNote()

      if (!error) {
        onOpenSnackbar('Note deleted.')
        onDeleteNote(id)
      }
    } catch (error) {
      onOpenSnackbar('Something went wrong!', 'error')
    }
  }

  const options = [
    {
      label: 'Edit',
      onClick: () =>
        goTo(`/v2/patient/${patientId}/notes/${id}`, {
          note,
          onSave: note => onUpdateNote(note),
        }),
    },
    {
      label: 'Remove',
      onClick: () => handleConfirmDeletion(),
    },
  ]

  return (
    <Item theme={theme.palette} classification={classification || 'yellow'}>
      <DateTime>
        <Typography bold fontSize="12px" sx={{ mb: '2px' }}>
          {format(date, 'MMMM dd, yyyy')}
        </Typography>
        <Typography uppercase fontSize="12px">
          {format(date, TIME_IN_US_FORMAT)}
        </Typography>
      </DateTime>
      <Title>
        <Typography fontSize="14px" sx={{ mb: '5px' }}>
          {parseHtmlToString(annotation)}
        </Typography>
        <Typography bold fontSize="14px">
          {`Dr. ${user.fullName}`}
        </Typography>
      </Title>
      <IconMenu
        options={options}
        icon={<MoreIcon fontSize="small" />}
        aria-label="More"
        title="More"
      />
    </Item>
  )
}

export default TreatmentNote
