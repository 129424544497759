import React from 'react'
import styled from 'styled-components'
import Label from '@/v2/components/common/FieldLabel'
import Button from '@/v2/components/common/Button'
import SelectField from '@/v2/components/common/SelectField'
import CheckboxField from '@/v2/components/common/CheckboxField'
import TextField from '@/v2/components/common/TextField'
import SelectCalendar from '@/v2/components/common/SelectCalendar'
import { formatTime } from '@/v2/utils/format'
import FormControl from '@mui/material/FormControl'

const Inline = styled.div`
  display: flex;
  column-gap: 10px;

  & > * {
    flex: 1;
  }
`

const DoctorWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const TimeOffForm = ({ values, error, clinic, rooms, doctors, onChange }) => {
  let debounce
  const handleChangeNote = value => {
    clearTimeout(debounce)
    debounce = setTimeout(() => {
      onChange({ notes: value })
    }, 300)
  }
  return (
    <>
      <FormControl variant="standard">
        <Label>Type</Label>
        <Inline>
          <Button
            key="clinic"
            variant={values.type === 'clinic' ? 'contained' : 'outlined'}
            onClick={() => onChange({ type: 'clinic', clinicId: clinic })}
          >
            Clinic
          </Button>
          <Button
            key="room"
            variant={values.type === 'room' ? 'contained' : 'outlined'}
            onClick={() => onChange({ type: 'room' })}
          >
            Room
          </Button>
          <Button
            key="doctor"
            variant={values.type === 'doctor' ? 'contained' : 'outlined'}
            onClick={() => onChange({ type: 'doctor' })}
          >
            Doctor
          </Button>
        </Inline>
      </FormControl>
      {values.type === 'doctor' && (
        <DoctorWrapper>
          <SelectField
            fullWidth
            noMargin
            name="doctor"
            label="Doctor"
            placeholder="Choose a doctor"
            options={doctors}
            value={values.doctorId}
            error={error.doctorId}
            onChange={value => onChange({ doctorId: value })}
          />
          <CheckboxField
            checked={values.virtual}
            label="Blocked time for virtual appointments"
            color="secondary"
            onChange={() => onChange({ virtual: !values.virtual })}
          />
        </DoctorWrapper>
      )}
      {values.type === 'room' && (
        <SelectField
          fullWidth
          noMargin
          name="room"
          label="Room"
          placeholder="Choose a room"
          options={rooms}
          value={values.roomId}
          error={error.roomId}
          onChange={value => onChange({ roomId: value })}
        />
      )}
      <Inline>
        <SelectCalendar
          label="Date"
          value={values.startsAtDate}
          error={error.startsAtDate}
          onChange={value => onChange({ startsAtDate: value })}
        />
        <TextField
          name="start-time"
          label="Start time"
          value={values.startsAtTime}
          error={error.startsAtTime}
          onChange={value => onChange({ startsAtTime: formatTime(value) })}
        />
        <TextField
          name="end-time"
          label="End time"
          value={values.endsAtTime}
          error={error.endsAtTime}
          onChange={value => onChange({ endsAtTime: formatTime(value) })}
        />
      </Inline>
      <TextField
        multiline
        label="Notes"
        rows={4}
        placeholder="Any specific notes about the blocked time"
        defaultValue={values.notes || ''}
        onChange={handleChangeNote}
      />
    </>
  )
}

export default TimeOffForm

TimeOffForm.defaultProps = {
  values: {
    type: 'clinic',
    clinicId: '',
    doctorId: '',
    roomId: '',
    virtual: false,
    startsAtDate: '',
    startsAtTime: '10:00 AM',
    endsAtTime: '10:30 AM',
    notes: '',
  },
  error: {},
  doctors: [],
  rooms: [],
}
