import React from 'react'
import { useTheme } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DefaultFooter from './defaults/Footer'
import DefaultLoader from './defaults/Loader'
import DefaultHeader from './defaults/Header'
import Box from '@mui/material/Box'

const Modal = ({
  isOpen = false,
  loading = false,
  header,
  loader,
  children,
  footer,
  title,
  cancelLabel = 'Cancel',
  confirmLabel = 'Save',
  disableBackdropClick = false,
  showConfirm = true,
  showClose = true,
  onClose,
  onCancel,
  onConfirm,
  submitDisabled,
  maxWidth = 'sm',
  minWidth,
  fullWidth,
}) => {
  const theme = useTheme()
  return (
    <Dialog
      open={isOpen}
      onClose={() =>
        disableBackdropClick ? '' : onClose({ header: true, footer: false })
      }
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      PaperProps={{
        style: {
          position: 'static',
          border: `1px solid ${theme.palette.primary.main}`,
          borderRadius: '10px',
          overflow: 'hidden',
          minWidth: minWidth,
        },
      }}
    >
      <DefaultHeader
        title={title}
        header={header}
        onClose={() => onClose({ header: true, footer: false })}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '24px',
          padding: '24px',
          maxHeight: '70vh',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        {loading ? !loader ? <DefaultLoader /> : loader : children}
        {loading ? null : !footer ? (
          <DefaultFooter
            cancelLabel={cancelLabel}
            confirmLabel={confirmLabel}
            showConfirm={showConfirm}
            showClose={showClose}
            onClose={() => onClose({ footer: true, header: false })}
            onCancel={onCancel}
            onConfirm={onConfirm}
            submitDisabled={submitDisabled}
          />
        ) : (
          footer
        )}
      </Box>
    </Dialog>
  )
}

export default Modal
