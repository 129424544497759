import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useHistory, useParams } from 'react-router-dom'
import TabsMui from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { usePatientContext } from '@/v2/contexts/PatientContext'
import { usePatientProfile } from '@/v2/hooks/patient/usePatient'
import Alert from '../Alert'
import { useUser } from '@/v2/hooks/useUser'

const Tabs = styled(TabsMui)`
  margin-right: 25px;
  display: inline-flex;
`

const NavigationTabs = () => {
  const { id, view } = useParams()
  const history = useHistory()
  const [tab, setTab] = useState(view || 'profile')
  const [showAlert, setShowAlert] = useState(false)
  const { patient, isLoading } = usePatientProfile(id)
  const { medicalAlerts, officeNotes, onSetPatient } = usePatientContext()
  const patientAlerts = [
    ...officeNotes.filter(({ alerts }) => alerts),
    ...medicalAlerts,
  ]
  const { user, isLoading: isLoadingUser } = useUser()

  useEffect(() => {
    // TODO: when we migrate to the medical alerts new endpoint, move the
    //        usePatientProfile call to /patient-profile component and call
    //         the endpoint to return the medical alerts here
    if (!isLoading) {
      onSetPatient(patient)
    }
  }, [patient, isLoading])

  useEffect(() => {
    setShowAlert(patientAlerts.length > 0)
  }, [medicalAlerts, officeNotes])

  const handleChange = (e, newTab) => {
    history.push(`/v2/patient/${id}/${newTab}`)
    const { pathname } = history.location
    // We need this verification because on treatment tab is possible to cancel
    //   the redirect to another page, se we set the current tab only when the
    //   redirect is fulfilled
    if (pathname.indexOf(newTab) >= 0) {
      setTab(newTab)
      setShowAlert(patientAlerts.length > 0)
    }
  }

  if(isLoadingUser) return null

  return (
    <>
      {showAlert && (
        <Alert alerts={patientAlerts} onClose={() => setShowAlert(false)} />
      )}

      <Tabs
        value={tab}
        className="patient-navigation"
        onChange={handleChange}
        aria-label="nav tabs"
      >
        <Tab value="profile" label="Profile" />
        <Tab value="treatment" label="Treatment" />
        {user && user?.id === 15 && <Tab value="insurance" label="Insurance" />}
        <Tab value="documents" label="Documents" />
        <Tab value="imaging" label="Imaging" />
        <Tab value="finance" label="Finance" />
      </Tabs>
    </>
  )
}

export default NavigationTabs
