// @flow
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'

export const required = opts => value => {
  const hasValue = !isNil(value) && value !== ''
  if (get(opts, 'if', true) && !hasValue) {
    return get(opts, 'message', 'Required field')
  }
}

export const equal = opts => value => {
  const isEqual = value === opts.to
  const to = get(opts, 'to', '')
  if (get(opts, 'if', true) && !isEqual) {
    return get(opts, 'message', `"${value}" should be equal to "${to}"`)
  }
}

export const length = opts => value => {
  const min = get(opts, 'min', 0)
  const max = get(opts, 'max', Infinity)

  const isBetween = value.length >= min && value.length <= max

  if (get(opts, 'if', true) && !isBetween) {
    return get(
      opts,
      'message',
      `The length should be between ${min} and ${max}`
    )
  }
}

export const format = opts => value => {
  const regex = get(opts, 'regex', /.*/)

  const matches = regex.exec(value)

  if (get(opts, 'if', true) && !matches) {
    return get(opts, 'message', 'The value does not match the format')
  }
}

export const isNotEmpty = opts => value => {
  const hasValue = !isNil(value) && !isEmpty(value)
  if (get(opts, 'if', true) && !hasValue) {
    return get(opts, 'message', 'Required field')
  }
}
