import styled from 'styled-components'

export const Values = styled.div`
  width: ${({ items }) => 100 / items}%;
  display: flex;
  gap: 0.5;
`

export const Placeholder = styled.div`
  display: flex;
  align-items: center;
  height: 23px;
`

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
}
