import React from 'react'
import styled from 'styled-components'
import { format } from 'date-fns'
import { useTheme } from '@mui/material/styles'
import Typography from '@/v2/components/common/Typography'
import { convertHoursToMinutes } from '@/v2/utils/convert'

const HourRule = styled.div`
  width: 82px;
  height: 1.3px;
  text-align: center;
  padding: 0px 13px;
`

const Slot = styled.div`
  height: 1.3px;
  ${({ isOffHour, theme }) =>
    !isOffHour ? `background: ${theme.background.default};` : ''}

  && > div {
    ${({ isFullHour, theme }) =>
      isFullHour ? `border-top: 1px solid ${theme.primary.main};` : ''}
    ${({ isHalfHour, theme }) =>
      isHalfHour ? `border-top: 1px dashed ${theme.primary.main};` : ''}
    ${({ isQuarterHour }) => (isQuarterHour ? 'border-top: none;' : '')}
  }

  && > div::before {
    content: "";
    z-index: 99;
    border-top: 1px dashed red;
    position: absolute;
    width: 100%;
    margin-top: -1px;
    ${({ isFullHour, theme }) =>
      isFullHour ? `border-top: 1px solid ${theme.primary.main};` : ''}
    ${({ isHalfHour, theme }) =>
      isHalfHour ? `border-top: 1px dashed ${theme.primary.main};` : ''}
    ${({ isQuarterHour }) => (isQuarterHour ? 'border-top: none;' : '')}
  }
`

const getExtraProps = hour => {
  if (hour % 60 === 0) return { isFullHour: '1' }
  if (hour % 60 === 30) return { isHalfHour: '1' }
  return { isQuarterHour: '1' }
}

const TimeSlot = ({ children, resource, value, offHours }) => {
  const theme = useTheme()
  const hour = convertHoursToMinutes(format(value, 'H:mm'))
  const resourceOffHours =
    offHours.find(items =>
      items.find(({ resourceId }) => resourceId === resource)
    ) || []
  const isOffHour = resourceOffHours.find(
    ({ startsAt, endsAt }) => hour >= startsAt && hour <= endsAt
  )

  return resource ? (
    <Slot
      theme={theme.palette}
      {...getExtraProps(hour)}
      isOffHour={isOffHour ? 1 : 0}
    >
      {children}
    </Slot>
  ) : (
    <HourRule>
      {hour % 60 === 0 && <Typography variant="h7">{children}</Typography>}
    </HourRule>
  )
}

export default TimeSlot
