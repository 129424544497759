import React, { useState } from 'react'
import Modal from '@/v2/components/common/Modal'
import { useSnackbarContext } from '@/v2/contexts/SnackbarContext'
import { useAddInsurance } from '@/v2/hooks/insurance/useInsurances'
import Form from './insurance-form/Form'
import { validateInsurance } from './insurance-form/validator'

const InsuranceForm = ({
  patient,
  modalType,
  insurance = {},
  isOpen,
  onClose,
}) => {
  const [values, setValues] = useState({
    groupNumber: insurance.groupNumber || '',
    payerId: '',
    relationship: insurance.relationship || '',
    memberId: insurance.memberId || '',
    payer: insurance?.payer?.name || '',
  })
  const [error, setError] = useState({})
  const { addInsurance } = useAddInsurance(patient.id)
  const { onOpenSnackbar } = useSnackbarContext()

  const handleAddInsurance = async () => {
    const body = {
      insurance: {
        ...values,
      },
    }
    try {
      const { error } = await addInsurance(body)
      if (!error) {
        onOpenSnackbar('Insurance added', 'success')
        onClose()
      }
    } catch (error) {
      console.log('error', error)
      onOpenSnackbar(`${error}`, 'error')
    }
  }

  const handleSubmit = async () => {
    const validErrors = await validateInsurance(values)
    setError(validErrors || {})

    if (!validErrors) {
      handleAddInsurance()
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      title="Add Insurance"
      confirmLabel="Add"
      cancelLabel={modalType === 'view' ? 'Close' : 'Cancel'}
      onConfirm={handleSubmit}
      onClose={onClose}
      showConfirm={modalType !== 'view'}
    >
      <Form
        values={values}
        error={error}
        disableFields={modalType === 'view'}
        onChange={value => setValues({ ...values, ...value })}
      />
    </Modal>
  )
}

export default InsuranceForm
