import React, { useMemo, useRef } from 'react'
import UploadList from './UploadList'
import Button from '@/v2/components/common/Button'
import { Actions, Loading } from './styles'
import useFolders from '@/v2/hooks/useFolders'
import useArchives from '@/v2/hooks/useArchives'
import SkeletonUploads from '@/v2/components/common/Skeleton/Uploads'
import { useTheme } from '@mui/material'
import useDetectDevice from '@/v2/hooks/useDetectDevice'
import CircularProgress from '@mui/material/CircularProgress'

const getFilesFromFolders = folders => {
  const files = []
  folders.forEach(folder => {
    if (folder.folders && folder.folders.length > 0) {
      const foldersFiles = getFilesFromFolders(folder.folders)
      files.push(...foldersFiles)
    }
    if (folder.archives && folder.archives.length > 0) {
      folder.archives.forEach(archive => {
        files.push(archive)
      })
    }
  })
  return files
}

const UploadsTab = ({ patientId }) => {
  const inputRef = useRef(null)
  const theme = useTheme()
  const { isMobile } = useDetectDevice()

  const { data, isLoading } = useFolders({
    patientId,
  })

  const archives = useMemo(() => {
    if (!data) return []
    const files = [...data.archives]

    if (data.folders && data.folders.length > 0) {
      const foldersFiles = getFilesFromFolders(data.folders)
      files.push(...foldersFiles)
    }

    return files
  }, [data])

  const {
    createArchive,
    deleteArchive,
    isLoadingCreate,
    isLoadingDelete,
  } = useArchives({
    patientId,
    folderId: data?.id,
  })

  const loading = isLoadingCreate || isLoadingDelete

  const handleUploadClick = () => {
    inputRef.current?.click()
  }

  const handleFileChange = e => {
    if (!e.target.files) return
    handleUpload(e.target.files[0])
  }

  const handleUpload = file => {
    const formData = new FormData()
    formData.append('archive[file]', file)
    formData.append(
      'archive[name]',
      file.name
        .split('.')
        .slice(0, -1)
        .join('.')
    )

    if (data) {
      createArchive(formData)
    }
  }

  return (
    <>
      {loading && (
        <Loading theme={theme.palette} isMobile={isMobile ? 1 : 0}>
          <CircularProgress color="secondary" />
        </Loading>
      )}
      <Actions>
        <Button
          variant="text"
          disabled={loading}
          color="secondary"
          onClick={handleUploadClick}
        >
          Upload new
        </Button>
      </Actions>
      {isLoading ? (
        <SkeletonUploads />
      ) : (
        <UploadList
          uploads={archives}
          deleteArchive={deleteArchive}
          patientId={patientId}
        />
      )}
      <input
        type="file"
        accept="image/png, image/jpeg, application/pdf, docx, doc, xls, xlsx, zip, rar"
        ref={inputRef}
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
    </>
  )
}

export default UploadsTab
