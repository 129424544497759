import React, { useState } from 'react'
import { useTheme } from '@mui/material/styles'
import Typography from '@/v2/components/common/Typography'
import Box from '@mui/material/Box'
import { Wrapper, Body, Header, Tabs, Tab, Content } from './styles'
import { usePatientContext } from '@/v2/contexts/PatientContext'
import InsurancesTab from './insurances-tab/InsurancesTab'
import EligibilitiesTab from './eligibilities-tab/EligibilitiesTab'

const Insurance = () => {
  const theme = useTheme()
  const [tab, setTab] = useState('insurances')
  const { patient } = usePatientContext()

  if (!patient.id) return null

  const tabsComponents = {
    insurances: <InsurancesTab />,
    eligibilities: <EligibilitiesTab />,
    claims: <Typography>Claims</Typography>,
  }

  return (
    <Wrapper theme={theme.palette}>
      <Box
        display={'flex'}
        flexDirection={'row'}
        alignItems={'center'}
        gap={2}
        mb={4}
      >
        <Typography variant="h1" fontSize="26px">
          Insurance
        </Typography>
      </Box>
      <Body theme={theme.palette}>
        <Header theme={theme.palette}>
          <Tabs
            value={tab}
            className="treatment-navigation"
            onChange={(e, newTab) => setTab(newTab)}
          >
            <Tab value="insurances" label="Insurances" />
            <Tab value="eligibilities" label="Eligibilities" />
            <Tab value="claims" label="Claims" />
          </Tabs>
        </Header>
        <Content>{tabsComponents[tab]}</Content>
      </Body>
    </Wrapper>
  )
}

export default Insurance
