import { IconButton } from '@mui/material'
import styled from 'styled-components'

export const List = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 20px;
  width: 100%;
`

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const UploadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  background: ${({ theme }) => theme.gray.light};
  border-radius: 3px;
`

export const UploadImageContainer = styled.div`
  height: 190px;
  width: 190px;
  position: relative;
`

export const UploadImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
`

export const UploadDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 190px;
`
export const UploadInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`

export const UploadActions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`

export const UploadButton = styled(IconButton)`
  border: 1px solid ${({ themecolors }) => themecolors.silver.dark} !important;
  border-radius: 3px !important;
  padding: 20px;
`

export const Loading = styled.div`
  background: ${({ theme }) => theme.black.main}38;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100vw;
  z-index: 999;

  ${({ isMobile }) =>
    isMobile
      ? `
        top: 101px;
        height: calc(100vh - 150px);
      `
      : `
        top: 0;
        left: 0;
        height: 100%;
  `}
`
