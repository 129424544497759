import styled from 'styled-components'

export const DailyView = styled.div`
  grid-row: 2;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
  height: 100%;

  & {
    .rbc-time-header-content {
      margin-left: -1px;
    }

    .rbc-time-content {
      overflow: hidden;
    }
  }
`

export const Week = styled.div`
  display: flex;
  overflow: scroll;
  width: 100%;
  height: 100%;

  .rbc-time-header-gutter {
    position: relative;
    top: -28px;
    height: 60px;
    margin-bottom: -28px;
  }

  .rbc-time-view-resources .rbc-time-header {
    overflow: visible !important;
  }

  .daily-view {
    overflow: visible;
  }
`

export const Column = styled.div`
  height: fit-content;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  overflow: visible;
  min-width: 850px;
  min-height: 100%;
  ${({ hasBorder, theme }) =>
    hasBorder ? `border-right: 1px solid ${theme.primary.main};` : ''}

  .rbc-time-header.rbc-overflowing {
    border-color: ${({ theme }) => theme.primary.main};
  }

  ${({ hasHourRule }) =>
    hasHourRule
      ? ''
      : `
    // hide hour rules
    .rbc-time-gutter, .rbc-time-header-gutter {
      display: none !important;
    }

    .rbc-time-header .rbc-time-header-content:nth-child(2) {
      border-left-color: transparent;
    }

    .rbc-time-column.rbc-day-slot:nth-child(2) .rbc-events-container {
      border-left-color: transparent;
    }
  `}
`

export const HeadlineWrapper = styled.div`
  display: flex;
`

export const Headline = styled.div`
  height: 24px;
  width: 100%;
  padding: 8px 8px 0 11px;
`

export const BlankHeadline = styled.div`
  width: 92px;
`

export const HourRuleWrapper = styled.div`
  width: 82px;
  height: fit-content;
  flex-shrink: 0;
  position: sticky;
  left: 0;
  top: 0;
  z-index: 999;
  padding-top: 25px;
  overflow: hidden;
  border-right: 1px solid ${({ theme }) => theme.primary.main};

  .rbc-time-content {
    overflow: hidden;
  }
`
