import React from 'react'
import styled from 'styled-components'
import AccordionMui from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const Wrapper = styled(AccordionMui)`
  &&& {
    border-bottom: 1px solid #D2D2D2;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  &&:before {
    background-color: #D2D2D2;
  }
`

const Title = styled(AccordionSummary)`
  && {
    padding: 0px;
  }
`

const StrippedAccordion = ({ title, content, ...others }) => (
  <Wrapper {...others}>
    <Title expandIcon={<ExpandMoreIcon />}>
      {title}
    </Title>
    <AccordionDetails sx={{ padding: '8px 0px 16px' }}>
      {content}
    </AccordionDetails>
  </Wrapper>
)

export default StrippedAccordion
