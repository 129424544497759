import styled from 'styled-components'
import OffBackground from '#/assets/images/v2/stripes-thin.png'
import HourRule from '#/assets/images/v2/hour-rule.png'

export const Wrapper = styled.div`
  ${({ isMobile }) =>
    isMobile ? 'width: calc(100% * 2); overflow-x: auto;' : 'width: 100%;'}

  & {
    .rbc-allday-cell {
      display: none;
    }

    .rbc-time-header-content > .rbc-row.rbc-row-resource,
    .rbc-time-view,
    .rbc-timeslot-group,
    .rbc-day-slot .rbc-timeslot-group:first-child .rbc-time-slot {
      border: none;
    }

    .rbc-time-content {
      border-top: none;
    }

    .rbc-time-view-resources .rbc-time-gutter,
    .rbc-time-view-resources .rbc-time-header-gutter,
    .rbc-time-header-content,
    .rbc-header,
    .rbc-time-content > * + * > * {
      border-color: ${({ theme }) => theme.primary.main};
    }

    .rbc-timeslot-group {
      width: 100%;
    }

    .rbc-time-content > .rbc-time-column.rbc-day-slot:nth-child(2) {
      width: calc(100% - 6px);
    }

    .rbc-current-time-indicator {
      display: none;
    }

    .rbc-time-content
      > .rbc-time-column.rbc-day-slot:nth-child(2)
      .rbc-current-time-indicator {
      background: transparent url(${HourRule}) no-repeat -8px -8px;
      z-index: 99;
      margin-top: -8px;
      margin-left: -82px;
      ${({ showTimeIndicator }) =>
        showTimeIndicator ? 'display: block !important;' : ''}

      width: 82px;
      height: 17px;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-left: 10px solid #000000;
    }

    .rbc-day-slot.rbc-time-column {
      background: url(${OffBackground});
    }
  }
`
