import React from 'react'
import Grid from '@mui/material/Grid'
import SelectField from '@/v2/components/common/SelectField'
import TextField from '@/v2/components/common/TextField'
import PassField from '@/v2/components/common/PassField'
import SelectCalendar from '@/v2/components/common/SelectCalendar'
import { REFERRED_OPTIONS } from '@/v2/constants'

const formatOptions = array => array.map(item => ({ value: item, label: item }))

const Form = ({ values, error, onChange }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextField
          fullWidth
          name="firstname"
          label="First name"
          placeholder="E.g.: John"
          value={values.firstName}
          error={error.firstName}
          onChange={value => onChange({ firstName: value })}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          name="lastname"
          label="Last name"
          placeholder="E.g.: Doe"
          value={values.lastName}
          error={error.lastName}
          onChange={value => onChange({ lastName: value })}
        />
      </Grid>
      <Grid item xs={12}>
        <PassField
          fullWidth
          name="ssn"
          label="SSN"
          value={values.ssn || ''}
          onChange={value => onChange({ ssn: value })}
        />
      </Grid>
      <Grid item xs={12}>
        <SelectCalendar
          label="Date of birth"
          labelFormat="MM/dd/yyyy"
          hasOccupancy={false}
          value={values.dob}
          onChange={value => onChange({ dob: value })}
        />
      </Grid>
      <Grid item xs={12}>
        <SelectField
          fullWidth
          noMargin
          name="gender"
          label="Gender"
          placeholder="Choose a gender"
          options={[
            { value: 0, label: 'Neutral' },
            { value: 1, label: 'Female' },
            { value: 2, label: 'Male' },
          ]}
          value={values?.gender}
          onChange={value => onChange({ gender: parseInt(value, 10) })}
        />
      </Grid>
      <Grid item xs={12}>
        <SelectField
          fullWidth
          noMargin
          name="referredBy"
          label="Heard about us from"
          placeholder="Choose an option"
          options={formatOptions(REFERRED_OPTIONS)}
          value={values.referredBy}
          error={error.referredBy}
          onChange={value => onChange({ referredBy: value })}
        />
      </Grid>
    </Grid>
  )
}

Form.defaultProps = {
  values: {
    firstName: '',
    lastName: '',
    dob: '',
    gender: 0,
    ssn: '',
    referredBy: '',
  },
  error: {},
}

export default Form
