import React from 'react'
import Grid from '@mui/material/Grid'
import TextField from '@/v2/components/common/TextField'
import { formatPhone } from '@/v2/utils/format'

const Form = ({ values, error, onChange }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          name="emergencyName"
          label="Name"
          value={values.emergencyName}
          onChange={value => onChange({ emergencyName: value })}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          name="emergencyRelationship"
          label="Relationship"
          value={values.emergencyRelationship}
          onChange={value => onChange({ emergencyRelationship: value })}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          name="emergencyEmail"
          label="Email"
          type="email"
          value={values.emergencyEmail}
          error={error.emergencyEmail}
          onChange={value => onChange({ emergencyEmail: value })}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          name="emergencyPhoneNumber"
          label="Mobile"
          value={values.emergencyPhoneNumber}
          error={error.emergencyPhoneNumber}
          onChange={value =>
            onChange({ emergencyPhoneNumber: formatPhone(value) })
          }
        />
      </Grid>
    </Grid>
  )
}

Form.defaultProps = {
  values: {
    emergencyName: '',
    emergencyRelationship: '',
    emergencyEmail: '',
    emergencyPhoneNumber: '',
  },
}

export default Form
