import React, { useState } from 'react'
import Modal from '@/v2/components/common/Modal'
import { useSnackbarContext } from '@/v2/contexts/SnackbarContext'
import { useAddCheckEligibility } from '@/v2/hooks/insurance/useEligibilities'
import Form from './eligibility-form/Form'
import { validateEligibility } from './eligibility-form/validator'
import { useProviders } from '@/v2/hooks/insurance/useEligibilities'
import { useInsurances } from '@/v2/hooks/insurance/useInsurances'

const EligibilityForm = ({ patient, modalType, isOpen, onClose }) => {
  const [values, setValues] = useState({
    insuranceId: '',
    providerNPI: '',
  })
  const [error, setError] = useState({})
  const { addCheckEligibility } = useAddCheckEligibility(patient.id)
  const { onOpenSnackbar } = useSnackbarContext()
  const { providers, isLoading: loadingProviders } = useProviders()
  const { insurances, isLoading: loadingInsurances } = useInsurances(patient.id)

  const handleCheckEligibility = async () => {
    const selectedProvider = providers.find(
      provider => provider.npi === values.providerNPI
    )
    try {
      const { error } = await addCheckEligibility({
        insuranceId: values.insuranceId,
        eligibility: {
          providerAttributes: {
            firstName:
              selectedProvider?.firstName ||
              selectedProvider.name.split(' ')[0],
            lastName:
              selectedProvider?.lastName || selectedProvider.name.split(' ')[1],
            npi: selectedProvider?.npi,
            taxId: selectedProvider?.taxId,
          },
        },
      })
      if (!error) {
        onOpenSnackbar('Eligibility added', 'success')
        onClose()
      }
    } catch (error) {
      console.log('error', error)
      onOpenSnackbar(`${error}`, 'error')
    }
  }

  const handleSubmit = async () => {
    const validErrors = await validateEligibility(values)
    setError(validErrors || {})

    if (!validErrors) {
      handleCheckEligibility()
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      title="Check Eligibility"
      confirmLabel="Check"
      cancelLabel={modalType === 'view' ? 'Close' : 'Cancel'}
      onConfirm={handleSubmit}
      onClose={onClose}
      showConfirm={modalType !== 'view'}
    >
      {!loadingProviders && !loadingInsurances && (
        <Form
          values={values}
          error={error}
          providers={providers}
          insurances={insurances}
          onChange={value => setValues({ ...values, ...value })}
        />
      )}
    </Modal>
  )
}

export default EligibilityForm
