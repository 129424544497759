import { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import getAppointment from '@/v2/graphql/queries/Appointment.gql'
import formatAppointment from '@/v2/components/forms/Appointment/toForm'
import getClinic from '@/v2/graphql/queries/Clinic.gql'

export const useLoadData = appointmentId => {
  const {
    data: dataClinic, loading: loadingClinic, error: errorClinic,
  } = useQuery(getClinic)
  const {
    data: dataAppointment,
    loading: loadingAppointment,
    error: errorAppointment,
  } = useQuery(getAppointment, { variables: { id: appointmentId } })

  const loading = loadingClinic || loadingAppointment
  const error = errorClinic || errorAppointment

  const types = dataClinic?.clinic?.appointmentTypes || {}
  const rooms = dataClinic?.clinic?.rooms || []
  const doctors = dataClinic?.clinic?.doctors || []
  const { appointment } = dataAppointment || {}

  return useMemo(
    () => ({
      loading,
      error,
      types,
      rooms,
      doctors,
      appointment: formatAppointment(appointment),
    }),
    [loading, error, types, rooms, doctors, appointment]
  )
}
