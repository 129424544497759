// @flow
import flatten from 'lodash/flatten'
import get from 'lodash/get'

import Datetime from '@/v2/modules/Datetime'

export const getWeekStart = (date, customStart = 1) => {
  // eslint-disable-next-line eqeqeq
  if (date.weekday == customStart) {
    return date.startOf('day')
  } else {
    const start = date.startOf('week')
    const defaultStart = start.weekday
    const daysToSubtract = (defaultStart + (7 - customStart)) % 7

    return start.minus({ day: daysToSubtract })
  }
}

export const getMonthWeeks = (month = Datetime.today()) => {
  const startDate = month.startOf('month').startOf('week')

  let weeks = []
  let currentWeek = startDate

  while (weeks.length < 6) {
    weeks.push(currentWeek)
    currentWeek = currentWeek.plus({ week: 1 })
  }

  return weeks
}

export const getWeekDays = (date = Datetime.today(), opts = {}) => {
  const omitWeekends = get(opts, 'omitWeekends', false)
  const weekStart = date.startOf('week')
  const weekEnd = date.endOf('week')

  let days = []
  let currentDay = weekStart

  while (currentDay.isBefore(weekEnd)) {
    if (!currentDay.isWeekend() || !omitWeekends) {
      days.push(currentDay)
    }
    currentDay = currentDay.plus({ day: 1 })
  }

  return days
}

export const getFullMonthDays = date => {
  const weeks = getMonthWeeks(date)
  const days = weeks.map(date => getWeekDays(date))

  return flatten(days)
}
