import React from 'react'
import styled from 'styled-components'
import SelectField from '@/v2/components/common/SelectField'
import TextField from '@/v2/components/common/TextField'
import { formatPhone } from '@/v2/utils/format'
import { REFERRED_OPTIONS } from '@/v2/constants'

const TwoColumns = styled.div`
  display: inline-flex;
  column-gap: 10px;

  & > div {
    width: 50%;
  }
`

const formatOptions = array => array.map(item => ({ value: item, label: item }))

const PatientSmallForm = ({ values, error, onChange }) => {
  return (
    <>
      <TwoColumns>
        <TextField
          name="firstname"
          label="First name"
          placeholder="E.g.: John"
          value={values.firstname}
          error={error.firstname}
          onChange={value => onChange({ firstname: value })}
        />
        <TextField
          name="lastname"
          label="Last name"
          placeholder="E.g.: Doe"
          value={values.lastname}
          error={error.lastname}
          onChange={value => onChange({ lastname: value })}
        />
      </TwoColumns>
      <TextField
        fullWidth
        name="email"
        label="Email"
        placeholder="E.g.: john@example.com"
        value={values.email}
        error={error.email}
        onChange={value => onChange({ email: value })}
      />
      <TextField
        fullWidth
        name="phone"
        label="Phone number"
        placeholder="(XXX) XXX-XXXX"
        value={values.phone}
        error={error.phone}
        onChange={value => onChange({ phone: formatPhone(value) })}
      />
      <SelectField
        fullWidth
        noMargin
        name="heardFrom"
        label="Heard about us from"
        placeholder="Choose an option"
        options={formatOptions(REFERRED_OPTIONS)}
        value={values.heardFrom}
        error={error.heardFrom}
        onChange={value => onChange({ heardFrom: value })}
      />
    </>
  )
}

PatientSmallForm.defaultProps = {
  values: {
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    heardFrom: '',
  },
  error: {},
}

export default PatientSmallForm
