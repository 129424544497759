import styled from 'styled-components'

export const Item = styled.div`
  padding: 20px 20px 14px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.primary.main};
  display: inline-flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  transition: 0.3s;

  ${({ selected, theme }) => selected ? `
    background: ${theme.primary.main};
    color: ${theme.primary.contrastText};
  ` : ''}

  &:hover {
    opacity: 0.75;
  }

  & .last-message {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 135px;
  }
`
export const PatientSection = styled.div`
  width: 100%;
`

export const LastMessageWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`

export const LastMessage = styled.div`
  display: flex;
  align-items: center;
`

export const Badge = styled.div`
  ${({ theme }) => `
    background: ${theme.primary.main};
    color: ${theme.primary.contrastText};
  `}
  width: 16px;
  height: 16px;
  border-radius: 50%;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`
