import { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import getClinic from '@/v2/graphql/queries/Clinic.gql'

export const useLoadData = () => {
  const { data, loading, error } = useQuery(getClinic)

  const clinic = data?.clinic?.id
  const types = data?.clinic?.appointmentTypes || {}
  const rooms = data?.clinic?.rooms || []
  const doctors = data?.clinic?.doctors || []

  return useMemo(
    () => ({ loading, error, clinic, rooms, doctors, types }),
    [loading, error, clinic, rooms, doctors, types]
  )
}
