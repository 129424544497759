import React, { useState } from 'react'
import styled from 'styled-components'
import { format, isSameDay } from 'date-fns'
import { useQueryClient } from '@tanstack/react-query'
import { useTheme } from '@mui/material/styles'
import MoreIcon from '@mui/icons-material/MoreVert'
import ArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { TIME_IN_US_FORMAT } from '@/v2/constants'
import Typography from '@/v2/components/common/Typography'
import IconMenu from '@/v2/components/common/IconMenu'
import Button from '@/v2/components/common/Button'
import useModals from '@/v2/hooks/useModals'
import { usePatientContext } from '@/v2/contexts/PatientContext'
import {
  getDateTimeWithoutTimezone,
  nowOnClinicTimezone,
} from '@/v2/utils/convert'
import AddNote from './AddNote'
import Note from './Note'

const Item = styled.div`
  display: flex;
  align-items: center;
  padding: 24px 26px;
  margin-top: 15px;
  border-radius: 3px;

  background: ${({ theme }) => theme.silver.main};
  ${({ isToday, theme }) =>
    isToday && `border-left: 4px solid ${theme.black.main};`}
`
const DateTime = styled.div`
  align-self: baseline;
  margin-right: 56px;
  min-width: 114px;
`
const Title = styled.div`
  flex: 1;
`

const AppointmentInfo = ({ appointment, openAddNote }) => {
  const queryClient = useQueryClient()
  const theme = useTheme()
  const { goTo } = useModals()
  const { patient } = usePatientContext()
  const { notes = [] } = appointment
  const { id, startsAt, appointmentType, doctor, room } = appointment

  const [showNotes, setShowNotes] = useState(openAddNote || false)
  const [showCreateNote, setShowCreateNote] = useState(openAddNote || false)
  const date = getDateTimeWithoutTimezone(startsAt)
  const currentDate = nowOnClinicTimezone()

  const isToday = isSameDay(currentDate, date)

  const options = [
    {
      label: '+ Add Note',
      onClick: () => setShowCreateNote(true),
    },
    {
      label: 'Edit',
      onClick: () => {
        goTo(`/v2/appointment/${id}`, {
          initialValues: { appointment: { ...appointment } },
          onEdit: () => {
            queryClient.refetchQueries({
              queryKey: ['PatientAppointments', `${patient.id}`],
            })
          },
        })
      },
    },
    {
      label: 'Cancel Appointment',
      onClick: () => alert('Comming soon...'),
    },
  ]

  return (
    <>
      <Item isToday={isToday} theme={theme.palette}>
        <DateTime>
          <Typography bold fontSize="12px" sx={{ mb: '2px' }}>
            {format(date, 'MMMM dd, yyyy')}
          </Typography>
          <Typography uppercase fontSize="12px">
            {format(date, TIME_IN_US_FORMAT)}
          </Typography>
        </DateTime>
        <Title>
          <Typography variant="h3" sx={{ mb: '5px' }}>
            {appointmentType.title}
          </Typography>
          <Typography fontSize="12px">
            {doctor && room
              ? `Dr. ${doctor.fullName} - ${room?.title}`
              : doctor?.fullName || room?.title}
          </Typography>

          {notes.length > 0 && (
            <Button
              type="text"
              sx={{ mt: '9px', fontWeight: '500' }}
              onClick={() => setShowNotes(!showNotes)}
            >
              {`${notes.length} Notes `}
              {showNotes ? <ArrowUpIcon /> : <ArrowDownIcon />}
            </Button>
          )}
        </Title>
        <IconMenu
          options={options}
          icon={<MoreIcon fontSize="small" />}
          aria-label="More"
          title="More"
        />
      </Item>
      {showNotes &&
        notes.map(note => (
          <Note key={note.id} note={note} appointmentId={id} />
        ))}
      {showCreateNote && (
        <AddNote
          appointmentId={id}
          onSave={() => setShowCreateNote(false)}
          onCancel={() => setShowCreateNote(false)}
        />
      )}
    </>
  )
}

export default AppointmentInfo
