import { useSnackbarContext } from '@/v2/contexts/SnackbarContext'
import { useMutation } from '@tanstack/react-query'
import { useHistory } from 'react-router-dom'
import { useAuthContext } from '@/v2/contexts/AuthContext'

const meta = document.querySelector('meta[name=csrf-token]')
export const csrfToken = meta && meta.getAttribute('content')

export const baseURL = window.location.origin

export const wsUrl =
  window.location.href.indexOf('.lvh.me:') >= 0
    ? `ws://${window.location.host}/cable`
    : `wss://${window.location.host}/cable`

export const baseURLGraphql = `${baseURL}/v2/graphql`

export const headers = {
  'Content-Type': 'application/json',
  'X-CSRF-Token': csrfToken,
}

export const STALE_TIME = 5 * 60 * 1000 // 5 minutes

export const requestMiddleware = requestInfo => {
  const { request, key, onSuccess } = requestInfo
  const { onOpenSnackbar } = useSnackbarContext()
  const { replace } = useHistory()
  const { setUser, setIsLoggedIn } = useAuthContext()
  const mutation = useMutation({
    mutationKey: key,
    mutationFn: async variables => {
      try {
        const result = await request(variables)

        if (result.status === 204) {
          // if the response doesn't has a body (204: No content)
          return {}
        }

        const data = await result.json()

        if (!result.ok) {
          const { errors, error, ...others } = data
          const fields = Object.keys(errors || others)

          const errorsMessage =
            errors &&
            errors[fields] &&
            errors[fields][0] &&
            `${fields[0]}: ${errors[fields][0]}`

          const othersMessage =
            others && others[fields[0]] && others[fields[0]][0]

          onOpenSnackbar(
            errorsMessage || othersMessage || 'Something went wrong.',
            'error'
          )

          return error
            ? { error }
            : {
                error: {
                  [fields[0]]: errors ? errorsMessage : othersMessage,
                },
              }
        }

        return data
      } catch (e) {
        throw new Error('Something went wrong.')
      }
    },
    onSuccess,
    onError: error => {
      if (error.status === 401) {
        setUser(null)
        setIsLoggedIn(false)
        replace('/v2/login')
      }
      console.log(error.status, error)
      onOpenSnackbar('Something went wrong.', 'error')
    },
  })

  return mutation
}
