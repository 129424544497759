// @flow
import camelCase from 'lodash/camelCase'
import mapKeys from 'lodash/mapKeys'
import zipObjectDeep from 'lodash/zipObjectDeep'

export const errorsFromJson = (json = '{}') => {
  if (json !== '{}') {
    const camelizedErrors = mapKeys(JSON.parse(json), (value, key) =>
      key
        .split('.')
        .map(camelCase)
        .join('.')
    )

    return zipObjectDeep(
      Object.keys(camelizedErrors),
      Object.values(camelizedErrors)
    )
  }
}
