import React, { useCallback } from 'react'
import { useTheme } from '@mui/material/styles'
import Modal from '@/v2/components/common/Modal'
import useModals from '@/v2/hooks/useModals'
import AccountCard from './AccountCard'
import { usePatientFinancePaymentMethods } from '@/v2/hooks/usePatientFinancePaymentMethods'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

const ManageAccounts = ({ isOpen, onClose, patient, params }) => {
  const theme = useTheme()
  const { goTo } = useModals()

  const { bankAccounts, isLoading, isError } = usePatientFinancePaymentMethods({
    patientId: patient.id,
  })

  const handleClose = useCallback(() => {
    if (params) {
      goTo(`/v2/patient/${patient.id}/finance/payment-plan/new`, {
        initialValues: {
          patient: patient,
          existingParams: params,
          paymentType: params.installments > 1 ? 'installments' : 'single',
        },
      })
    } else {
      onClose()
    }
  }, [params, goTo, patient, onClose])

  if (isError) return 'Unable to load saved payment methods'

  return (
    <Modal
      loading={isLoading}
      isOpen={isOpen}
      onClose={onClose}
      onCancel={handleClose}
      title={'Manage Bank Accounts'}
      footer={<></>}
      maxWidth={'xs'}
      fullWidth
    >
      <Box
        sx={{
          mb: 2,
          '& > div': {
            padding: '24px 0 20px 0',
            borderTop: `1px solid ${theme.palette.primary.light}`,
            borderBottom: `1px solid ${theme.palette.primary.light}`,
          },
          '& > div:first-child': {
            borderTop: 'none',
          },
        }}
      >
        {bankAccounts.length > 0 ? (
          bankAccounts.map(card => (
            <AccountCard
              key={`card-${card.id}`}
              card={card}
              patient={patient}
              onClose={onClose}
              count={bankAccounts.length}
            />
          ))
        ) : (
          <Box bgcolor={'gray.light'} borderRadius={2} px={2} py={2}>
            <Typography variant="h4" textAlign={'center'} fontWeight={'400px'}>
              You don't have any bank accounts saved.
            </Typography>
          </Box>
        )}
      </Box>
    </Modal>
  )
}

export default ManageAccounts
