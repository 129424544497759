import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Typography from '@/v2/components/common/Typography'
import { useSnackbarContext } from '@/v2/contexts/SnackbarContext'
import { usePatientContext } from '@/v2/contexts/PatientContext'
import useModals from '@/v2/hooks/useModals'
import { useEligibilities } from '@/v2/hooks/insurance/useEligibilities'
import Button from '@/v2/components/common/Button'
import Box from '@mui/material/Box'
import EligibilityInfo from './EligibilityInfo'

const EligibilitiesTab = () => {
  const { goTo } = useModals()
  const { id } = useParams()
  const { patient } = usePatientContext()
  const { eligibilities, isLoading, isError } = useEligibilities(id)
  const { onOpenSnackbar } = useSnackbarContext()

  useEffect(() => {
    if (!isLoading && !!isError)
      onOpenSnackbar(
        "It wasn't possible to load the patient eligibilities.",
        'error'
      )
  }, [isLoading, isError, onOpenSnackbar])

  if (isLoading)
    return (
      <Typography
        variant="h5"
        fontWeight="300"
        sx={{ textAlign: 'center', marginTop: '40px' }}
      >
        Loading...
      </Typography>
    )

  return (
    <>
      <Box display={'flex'} flexDirection={'row-reverse'}>
        <Button
          variant="text"
          onClick={() =>
            goTo(`/v2/patient/${patient.id}/eligibility/check`, {
              modalType: 'create',
              patient,
            })
          }
        >
          <Typography variant="h5">Eligibility Check</Typography>
        </Button>
      </Box>
      {eligibilities.map(item => (
        <EligibilityInfo key={item.id} eligibility={item} patient={patient} />
      ))}
      {eligibilities.length === 0 && (
        <Typography
          variant="h5"
          fontWeight="300"
          sx={{ textAlign: 'center', marginTop: '40px' }}
        >
          No eligibilities found
        </Typography>
      )}
    </>
  )
}

export default EligibilitiesTab
