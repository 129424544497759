import { INFOBAR_HEIGHT } from '@/v2/constants'
import TabsMui from '@mui/material/Tabs'
import TabMui from '@mui/material/Tab'
import styled from 'styled-components'

// start tab content holder
export const Wrapper = styled.div`
  background: ${({ theme }) => theme.silver.main};
  padding: 38px 64px 60px 48px;
  height: calc(100% - ${INFOBAR_HEIGHT});
  overflow-y: auto;
`

export const Body = styled.div`
  background: ${({ theme }) => theme.white.main};
  border-radius: 3px;
  min-height: calc(100% - 100px);
`

export const Header = styled.div`
  height: 60px;
  display: flex;
  align-items: end;
  padding-bottom: 4px;
  padding-left: 28px;
  border-bottom: 1px solid ${({ theme }) => theme.silver.dark};
`

export const Tabs = styled(TabsMui)`
  margin-right: 25px;
  display: inline-flex;

  & .MuiTabs-indicator {
    height: 3px;
  }
`
export const Tab = styled(TabMui)`
  && {
    padding: 2px;
    margin-right: 30px;
  }
`
export const Content = styled.div`
  padding: 37px 28px;
`
// end tab content holder

// start inner tab content
export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  margin-top: 15px;
  border-radius: 4px;
  background: ${({ theme }) => theme.silver.main};
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center; /* Center content vertically */
  justify-content: space-between; /* Distribute space evenly */
  gap: 10px; /* Add equal space between elements */
  width: 100%;
`;

export const Value = styled.div`
  text-align: left;
  flex: 1; /* Ensure Value takes up equal space */
`;

export const SmallValue = styled(Value)`
  flex: 0.5; /* Ensure SmallValue takes up less space */
`;
// end inner tab content
