import { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import getClinic from '@/v2/graphql/queries/Clinic.gql'

const formatToSelect = list => list.map(({ id, fullName, title }) => ({
  value: id,
  label: fullName || title,
}))

export const useLoadData = () => {
  const { data, loading, error } = useQuery(getClinic)

  const clinic = data?.clinic?.id
  const rooms = useMemo(
    () => formatToSelect(data?.clinic?.rooms || []),
    [data]
  )
  const doctors = useMemo(
    () => formatToSelect(data?.clinic?.doctors || []),
    [data]
  )

  return useMemo(
    () => ({ loading, error, clinic, rooms, doctors }),
    [loading, error, clinic, rooms, doctors]
  )
}
