import { useQuery, useQueryClient } from '@tanstack/react-query'
import { baseURL, requestMiddleware, headers } from '@/v2/constants/requests'

export const useInsurances = patientId => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ['Insurances', `${patientId}`],
    queryFn: async () => {
      const res = await fetch(
        `${baseURL}/v2/rest/patients/${patientId}/insurances.json`
      )
      if (!res.ok) {
        throw new Error("We couldn't find the patient insurances")
      }
      const result = await res.json()
      return result
    },
    enabled: !!patientId,
  })

  return { insurances: data, isLoading, isError }
}

export const useAddInsurance = patientId => {
  const queryClient = useQueryClient()
  const { mutateAsync: addInsurance, ...others } = requestMiddleware({
    key: ['AddInsurance', `${patientId}`],
    request: async insurance =>
      await fetch(`${baseURL}/v2/rest/patients/${patientId}/insurances.json`, {
        method: 'POST',
        headers,
        body: JSON.stringify(insurance),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['Insurances', `${patientId}`],
      })
    },
  })

  return { addInsurance, ...others }
}
