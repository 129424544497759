import React, { useState } from 'react'
import { useTheme } from '@mui/material/styles'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Chip from '@mui/material/Chip'
import CancelIcon from '@mui/icons-material/Cancel'
import { Values, Placeholder, MenuProps } from './styles'

const MultipleSelect = ({
  chipOptions,
  options,
  value,
  placeholder,
  onChange,
  ...others
}) => {
  const [selected, setSelected] = useState(value || [])
  const theme = useTheme()

  const handleChange = ({ target: { value } }) => {
    // On autofill we get a stringified value.
    const newSelected = typeof value === 'string' ? value.split(',') : value
    setSelected(newSelected)
    onChange(newSelected)
  }

  const handleDelete = option => {
    const newSelected = selected.filter(item => item !== option)
    setSelected(newSelected)
    onChange(newSelected)
  }

  return (
    <FormControl variant="standard" sx={{ m: 1, width: 200 }}>
      <Select
        multiple
        displayEmpty
        value={selected}
        MenuProps={MenuProps}
        onChange={handleChange}
        renderValue={selected => (
          <Values items={selected.length}>
            {selected.length === 0 && <Placeholder>{placeholder}</Placeholder>}
            {selected.map(value => {
              const type = options.find(option => value === option.value)
              return (
                <Chip
                  key={value}
                  size="small"
                  label={type.label}
                  title={type.label}
                  sx={{ background: theme.palette[type.color].type }}
                  deleteIcon={
                    <CancelIcon
                      onMouseDown={event => event.stopPropagation()}
                    />
                  }
                  onDelete={() => handleDelete(type.value)}
                />
              )
            })}
          </Values>
        )}
        {...others}
      >
        {placeholder && (
          <MenuItem disabled value="">
            {placeholder}
          </MenuItem>
        )}
        {options.map(({ value, label, color }) => (
          <MenuItem key={value} value={value}>
            {chipOptions ? (
              <Chip
                size="small"
                label={label}
                sx={{ background: theme.palette[color].type }}
              />
            ) : (
              label
            )}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default MultipleSelect
