import React from 'react'
import { ContentWrapper, SmallValue, Value, Item } from '../styles'
import { format, parseISO } from 'date-fns'
import { useTheme } from '@mui/material/styles'
import MoreIcon from '@mui/icons-material/MoreVert'
import Typography from '@/v2/components/common/Typography'
import IconMenu from '@/v2/components/common/IconMenu'
import useModals from '@/v2/hooks/useModals'

const InsuranceInfo = ({ insurance, patient }) => {
  const theme = useTheme()
  const { createdAt, payer } = insurance
  const { goTo } = useModals()

  const options = [
    {
      label: 'View',
      onClick: () =>
        goTo(`/v2/patient/${patient.id}/insurance/view`, {
          modalType: 'view',
          insurance,
          patient,
        }),
    },
    {
      label: 'Remove',
      onClick: () => alert('Comming soon...'),
    },
  ]

  return (
    <Item theme={theme.palette}>
      <ContentWrapper>
        <SmallValue>
          <Typography fontSize="14px" sx={{ mb: '2px' }}>
            Created
          </Typography>
          <Typography bold fontSize="14px" sx={{ mb: '2px' }}>
            {format(parseISO(createdAt), 'MMMM dd, yyyy HH:mm')}
          </Typography>
        </SmallValue>
        <Value>
          <Typography fontSize="14px" sx={{ mb: '2px' }}>
            Insurance
          </Typography>
          <Typography bold fontSize="14px" sx={{ mb: '2px' }}>
            {payer.name}
          </Typography>
        </Value>
        <Value>
          <Typography fontSize="14px" sx={{ mb: '2px' }}>
            ID
          </Typography>
          <Typography bold fontSize="14px" sx={{ mb: '2px' }}>
            {payer.code}
          </Typography>
        </Value>
      </ContentWrapper>
      <IconMenu
        options={options}
        icon={<MoreIcon fontSize="small" />}
        aria-label="More"
        title="More"
      />
    </Item>
  )
}

export default InsuranceInfo
