import React from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { format } from 'date-fns'
import { useTheme } from '@mui/material/styles'
import MoreIcon from '@mui/icons-material/MoreVert'
import BellIcon from '@mui/icons-material/Notifications'
import Typography from '@/v2/components/common/Typography'
import IconButton from '@/v2/components/common/IconButton'
import IconMenu from '@/v2/components/common/IconMenu'
import useModals from '@/v2/hooks/useModals'
import { getDateTimeWithoutTimezone } from '@/v2/utils/convert'
import { parseHtmlToString } from '@/v2/utils/helpers'
import { useProfileNotes } from '@/v2/hooks/useProfileNotes'

const Item = styled.div`
  display: flex;
  align-items: center;
  padding: 24px 26px;
  margin-top: 15px;
  border-radius: 3px;
  background: ${({ theme, classification }) => theme[classification].note};
`
const Title = styled.div`
  flex: 1;
  opacity: ${({ alerts }) => (alerts ? '1' : '0.5')};
`

const Note = ({ note }) => {
  const theme = useTheme()
  const { goTo } = useModals()
  const { id: patientId } = useParams()
  const { id, classification, annotation, createdAt, user, alerts } = note
  const date = getDateTimeWithoutTimezone(createdAt)
  const { deleteProfileNote, updateProfileNote } = useProfileNotes()

  const options = [
    {
      label: 'Edit',
      onClick: () =>
        goTo(`/v2/patient/${patientId}/notes/${note.id}`, {
          note,
        }),
    },
    {
      label: 'Remove',
      onClick: async () => {
        await deleteProfileNote({
          note: {
            id,
            patientId,
          },
        })
      },
    },
  ]

  const handleChangeAlert = async () =>
    updateProfileNote({
      note: {
        id: note.id,
        annotation: note.annotation,
        alerts: !alerts,
        patientId,
      },
    })

  return (
    <Item theme={theme.palette} classification={classification || 'yellow'}>
      <Title alerts={alerts ? 1 : 0}>
        <Typography fontSize="14px" bold sx={{ mb: '5px' }}>
          {parseHtmlToString(annotation)}
        </Typography>
        <Typography fontSize="12px">{format(date, 'MMMM dd, yyyy')}</Typography>
        <Typography fontSize="12px">{`Dr. ${user.fullName}`}</Typography>
      </Title>
      <IconButton
        title={alerts ? 'Hide alert' : 'Show alert'}
        aria-label={alerts ? 'Hide alert' : 'Show alert'}
        onClick={handleChangeAlert}
      >
        <BellIcon fontSize="small" sx={{ opacity: alerts ? '1' : '0.5' }} />
      </IconButton>
      <IconMenu
        options={options}
        icon={<MoreIcon fontSize="small" />}
        aria-label="More"
        title="More"
      />
    </Item>
  )
}

export default Note
