export const SIDEBAR_WIDTH = '280px'
export const INFOBAR_HEIGHT = '120px'
export const TOPBAR_HEIGHT = '60px'
export const TOPBAR_HEIGHT_NUMBER = 60
export const TOPBAR_MOBILE_HEIGHT = '50px'
export const PATIENT_BOTTOM_MOBILE_HEIGHT = '54px'
export const CALENDAR_SLOT_TIME = 15
export const DATE_QUERY_FORMAT = 'dd/MM/yyyy'
export const DATE_PARAM_FORMAT = 'yyyy-MM-dd'
export const DATE_FORMAT = 'MMMM, dd yyyy'
export const FULL_DATETIME_FORMAT = 'MMMM, dd yyyy - hh:mm aa'
export const TIME_IN_US_FORMAT = 'hh:mm aa'
export const DAY_IN_US_FORMAT = 'MM/dd/yyyy'
export const WEEK_DAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]
export const HEADER_HEIGHT = '64px'
export const HEADER_HEIGHT_NUMBER = 64
export const GENDER = ['Neutral', 'Female', 'Male']
export const INTAKE_FORM_STATUS = {
  completed: { color: 'green', label: 'COMPLETED' },
  reopened: { color: 'green', label: 'REOPENED' },
  unsent: { color: 'gray', label: 'PENDING REQUEST FOR SIGNATURE' },
  sent: { color: 'red', label: 'PENDING SIGNATURE' },
}
export const CONTRACT_STATUS = {
  signed: { color: 'green', label: 'SIGNED' },
  unsigned: { color: 'red', label: 'PENDING SIGNATURE' },
  archived: { color: 'gray', label: 'ARCHIVED' },
}
export const CONSENT_STATUS = {
  signed: { color: 'green', label: 'SIGNED' },
  pending: { color: 'red', label: 'PENDING SIGNATURE' },
  sent: { color: 'red', label: 'PENDING SIGNATURE' },
}

export const REFERRED_OPTIONS = [
  'Invisalign Website',
  'Invisalign Concierge',
  'Natick Mall',
  'Google',
  'Facebook',
  'Instagram',
  'Walked By',
  'Gilt City',
  'Groupon',
  'Yelp',
  'PWC',
  'CityYear',
  'Wayfair',
  'Friend/Family Referred Me',
  'Other',
]
export const MEDICAL_ALERTS = [
  { name: 'pregnant', label: 'Pregnant', color: 'rose' },
  { name: 'premedication', label: 'Premedication', color: 'turquoise' },
  { name: 'latexAllergy', label: 'Latex', color: 'indigo' },
]
