import React from 'react'
import styled from 'styled-components'
import useDetectDevice from '@/v2/hooks/useDetectDevice'
import Image from './Image'

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${({ isMobile }) => (isMobile ? 'center' : 'flex-start')};
  gap: 1vw;
  overflow-y: auto;
  width: ${({ isMobile }) => (isMobile ? '100%' : 'calc(900px + 2vw)')};
  padding-bottom: 50px;
`

const Gallery = ({ series }) => {
  const { isMobile } = useDetectDevice()
  if (series.images.length === 0) return <Image />

  return (
    <Wrapper isMobile={isMobile ? 1 : 0}>
      <Image />
      {series.images.map(image => (
        <Image key={image.id} id={image.id} src={image.url} />
      ))}
    </Wrapper>
  )
}

export default Gallery
