import React, { useEffect } from 'react'
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { Buttons, Disclaimer, Form, Input, InputWrapper, Row } from './styles'
import useTheme from '@mui/material/styles/useTheme'
import { z } from 'zod'
import { usePatientFinancePaymentMethods } from '@/v2/hooks/usePatientFinancePaymentMethods'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { useStripeClientSecret } from '@/v2/hooks/useStripeClientSecret'
import Typography from '@/v2/components/common/Typography'
import Label from '@/v2/components/common/FieldLabel'
import ActionButton from '@/v2/components/common/ActionButton'
import usePaymentPlan from '@/v2/hooks/usePaymentPlan/usePaymentPlan'
import usePaymentMethods from '@/v2/hooks/usePaymentMethods'
import FormControl from '@mui/material/FormControl'
import FormErrorMessage from '@/v2/components/common/FormErrorMessage'

const schema = z
  .object({
    zipcode: z
      .string()
      .max(5, 'Zipcode is too long')
      .nullable(),
    cardNumberValid: z.boolean(),
    cardExpiryValid: z.boolean(),
    cardCvcValid: z.boolean(),
  })
  .refine(
    data => {
      return data.cardNumberValid && data.cardExpiryValid && data.cardCvcValid
    },
    {
      message: 'Card is invalid',
      path: ['cardNumberValid'],
    }
  )
  .refine(
    // TODO: remove this after the 209 cards are created
    data => {
      if (data.zipcode && data.zipcode.length > 0 && data.zipcode.length < 5) {
        return false
      }
      return true
    },
    {
      message: 'Zipcode is invalid',
      path: ['zipcode'],
    }
  )

const CardForm = ({ patientId, backButton, onSuccess, reverse = false }) => {
  const theme = useTheme()
  const stripe = useStripe()
  const elements = useElements()

  const { createClientSecret } = useStripeClientSecret()
  const { refetch } = usePatientFinancePaymentMethods({
    patientId: patientId,
  })

  const { checkDuplicatePaymentPlan } = usePaymentPlan()
  const { updateInvoicesPaymentMethod } = usePaymentMethods()

  const {
    handleSubmit,
    register,
    setValue,
    setError,
    reset,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm({
    mode: 'onSubmit',
    resolver: zodResolver(schema),
    defaultValues: {
      zipcode: '',
    },
  })

  useEffect(() => {
    if (isSubmitSuccessful) {
      if (onSuccess) {
        onSuccess()
      }
      refetch()
      elements.getElement(CardNumberElement).clear()
      elements.getElement(CardExpiryElement).clear()
      elements.getElement(CardCvcElement).clear()
      reset()
    }
  }, [isSubmitSuccessful, onSuccess, refetch, reset, elements])

  const onSubmit = async data => {
    try {
      if (!stripe) return // Return while is not loaded yet

      const createTokenRes = await stripe.createToken(
        elements.getElement(CardNumberElement),
        {
          currency: 'usd',
        }
      )

      const { token, error: tokenError } = createTokenRes

      if (tokenError) {
        throw new Error(tokenError.message)
      }

      if (token) {
        const { patientFinanceDuplicateCard } = await checkDuplicatePaymentPlan(
          {
            patientId: patientId,
            tokenId: token.id,
          }
        )

        if (patientFinanceDuplicateCard?.duplicate) {
          throw new Error('You already have this credit card')
        }

        const { createSetupIntent } = await createClientSecret({
          patientId: patientId,
        })
        const { clientSecret } = createSetupIntent

        if (!clientSecret) return

        const res = await stripe.confirmCardSetup(clientSecret, {
          payment_method: {
            card: elements.getElement(CardNumberElement),
            billing_details: {
              address: {
                postal_code: data.zipcode,
              },
            },
          },
        })

        if (res.error) {
          throw new Error(res.error.message)
        }

        await updateInvoicesPaymentMethod({
          patientId: patientId,
        })

        return res
      }
      if (createTokenRes.error) throw new Error(createTokenRes.error)
      throw new Error('Something went wrong')
    } catch (error) {
      return setError('root.clientSecret', {
        type: 'manual',
        message: error.message,
      })
    }
  }

  const handleChange = (event, fieldName) => {
    if (event?.complete) {
      return setValue(fieldName, true)
    }
    return setValue(fieldName, false)
  }

  const onSubmitEvent = e => {
    e.preventDefault()
    e.stopPropagation()
    handleSubmit(onSubmit)()
  }

  return (
    <Form onSubmit={onSubmitEvent} theme={theme.palette}>
      <FormErrorMessage errors={errors} name="root.clientSecret" />

      <FormControl variant="standard">
        <Label>Card number</Label>
        <InputWrapper error={!!errors.cardNumberValid}>
          <CardNumberElement
            onReady={e => e.focus()}
            onChange={e => handleChange(e, 'cardNumberValid')}
          />
        </InputWrapper>
        {errors.cardNumberValid && (
          <Typography variant="error">
            {errors.cardNumberValid.message}
          </Typography>
        )}
      </FormControl>

      <Row>
        <FormControl variant="standard">
          <Label>Expires</Label>
          <InputWrapper error={!!errors.cardExpiryValid}>
            <CardExpiryElement
              onChange={e => handleChange(e, 'cardExpiryValid')}
            />
          </InputWrapper>
          {errors.cardExpiryValid && (
            <Typography variant="error">
              {errors.cardExpiryValid.message}
            </Typography>
          )}
        </FormControl>
        <FormControl variant="standard">
          <Label>CVC</Label>
          <InputWrapper error={!!errors.cardCvcValid}>
            <CardCvcElement
              onChange={e => handleChange(e, 'cardCvcValid')}
              options={{ placeholder: '123' }}
            />
          </InputWrapper>
          {errors.cardCvcValid && (
            <Typography variant="error">
              {errors.cardCvcValid.message}
            </Typography>
          )}
        </FormControl>
        <FormControl variant="standard">
          <Label>Zipcode</Label>
          <Input
            id={'zipcode'}
            {...register('zipcode')}
            error={!!errors.zipcode}
            helperText={errors.zipcode?.message}
            placeholder={'20191'}
          />
        </FormControl>
      </Row>

      <Disclaimer theme={theme.palette}>
        By saving your card details, you authorize Smilebar to save your
        information for future purchases, subscriptions and renewals.
      </Disclaimer>

      <Buttons reverse={reverse} theme={theme.palette}>
        {backButton ? backButton() : null}
        <ActionButton
          className="text-background"
          color="secondary"
          variant="contained"
          disabled={isSubmitting}
          loading={isSubmitting}
          type="submit"
        >
          Add Card
        </ActionButton>
      </Buttons>
    </Form>
  )
}

export default CardForm
