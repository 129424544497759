import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { themeOptions } from '@/v2/themes/Silver'
import { ModalProvider as RoutesProvider } from '@/v2/contexts/RoutesContext'
import { SIDEBAR_WIDTH } from '@/v2/constants'
import useDetectDevice from '@/v2/hooks/useDetectDevice'
import Sidebar from '@/v2/containers/sidebar'
import Topbar from './Topbar'
import TopbarMobile from './Topbar/mobile'

const Wrapper = styled.div`
  display: flex;
  overflow-y: auto;
  position: relative;

  *,
  *:after,
  *:before {
    font-family: ${({ fontFamily }) => fontFamily};
    letter-spacing: ${({ letterSpacing }) => letterSpacing};
  }

  b,
  strong {
    font-weight: 500;
  }
`

const Content = styled.div`
  width: ${({ isMobile, isLogin }) =>
    isMobile || isLogin ? '100%' : `calc(100% - ${SIDEBAR_WIDTH})`};
  display: flex;
  flex-direction: column;

  @media (max-width: 1360px) and (min-width: 769px) {
    min-width: 1055px;
  }
`

const ThemeContainer = () => {
  const location = useLocation()
  const [isLoginScreen, setIsLoginScreen] = useState(true)
  const theme = createTheme(themeOptions)
  const { isMobile } = useDetectDevice()
  const showSidebar = !isMobile && !isLoginScreen
  const authRoutes = ['login', 'new-password', 'reset-password']

  useEffect(() => {
    setIsLoginScreen(
      authRoutes.some(route => location.pathname.includes(route))
    )
  }, [location])

  return (
    <ThemeProvider theme={theme}>
      <RoutesProvider>
        {page => (
          <Wrapper
            fontFamily={theme.typography.fontFamily}
            letterSpacing={theme.typography.letterSpacing || '0'}
          >
            {showSidebar && <Sidebar />}
            <Content isMobile={isMobile} isLogin={isLoginScreen}>
              {!isLoginScreen && (!isMobile ? <Topbar /> : <TopbarMobile />)}
              {page}
            </Content>
          </Wrapper>
        )}
      </RoutesProvider>
    </ThemeProvider>
  )
}

export default ThemeContainer
