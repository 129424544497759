import styled from 'styled-components'
import { TOPBAR_HEIGHT_NUMBER, HEADER_HEIGHT_NUMBER } from '@/v2/constants'

const LIST_Y_TOP = 116
const numberToGetMaxHeight = TOPBAR_HEIGHT_NUMBER + HEADER_HEIGHT_NUMBER + LIST_Y_TOP + 1

export const ListHeader = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.primary.main};
  width: 280px;
`

export const ListBody = styled.div`
  max-height: calc(100vh - ${numberToGetMaxHeight}px);
  overflow: auto;
`
