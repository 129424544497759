import React from 'react'
import styled from 'styled-components'
import { useTheme } from '@mui/material/styles'

const Line = styled.div`
  width: ${({ width }) => (width ? width : '100%')};
  margin: ${({ margin }) => margin};
  border-radius: ${({ rounded }) => rounded};
  border-bottom: ${({ strokeWidth, color }) => `${strokeWidth} solid ${color}`};
`

const Divider = ({
  color = 'divider',
  width,
  margin = '0',
  strokeWidth = '1px',
  rounded = '8px',
}) => {
  const theme = useTheme()
  return (
    <Line
      color={theme.palette[color].main}
      width={width}
      margin={margin}
      rounded={rounded}
      strokeWidth={strokeWidth}
    />
  )
}

export default Divider
