import React, { useMemo } from 'react'
import { useTheme } from '@mui/material/styles'
import { Calendar as BigCalendar, dateFnsLocalizer } from 'react-big-calendar'
import {
  format,
  parse,
  startOfWeek,
  getDay,
  isSameDay,
  isSameWeek,
} from 'date-fns'
import enUS from 'date-fns/locale/en-US'
import {
  convertMinutesToHours,
  nowOnClinicTimezone,
  nowOnTimezone,
} from '@/v2/utils/convert'
import useModals from '@/v2/hooks/useModals'
import useDetectDevice from '@/v2/hooks/useDetectDevice'
import { Wrapper } from './styles'
import TimeSlot from './TimeSlot'
import ColumnTitle from './ColumnTitle'
import Event from './Event'

const locales = { 'en-US': enUS }

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
})

const Calendar = ({
  isHourRuleOnly,
  view = 'day',
  subjectView,
  columns,
  events,
  date,
  startHour,
  endHour,
  offHours,
}) => {
  const { goTo } = useModals()
  const { isMobile } = useDetectDevice()
  const formattedDate = date.replace(/-/g, '/')
  const theme = useTheme()
  const components = useMemo(
    () => ({
      eventWrapper: props => (isHourRuleOnly ? <></> : <Event {...props} />),
      timeSlotWrapper: props => <TimeSlot {...props} offHours={offHours} />,
      resourceHeader: props =>
        isHourRuleOnly ? <></> : <ColumnTitle {...props} />,
      toolbar: () => <></>,
    }),
    [isHourRuleOnly, offHours]
  )
  const showTimeIndicator =
    view === 'week'
      ? isSameWeek(new Date(formattedDate), nowOnClinicTimezone())
      : isSameDay(new Date(formattedDate), nowOnClinicTimezone())

  let debounce
  const handleOpenCreateEvent = (start, resourceId) => {
    clearTimeout(debounce)
    debounce = setTimeout(() => {
      const isVirtual = subjectView === 'room' && resourceId.includes('doctor')
      goTo('/v2/event/new', {
        initialValues: { start, resourceId, virtual: isVirtual },
      })
    }, 500)
  }
  const handleOpenEditEvent = event => {
    clearTimeout(debounce)
    debounce = setTimeout(() => {
      if (event.type === 'timeOff') {
        return goTo(`/v2/time-off/${event.id}/edit`, {
          initialValues: { timeoff: event },
        })
      } else {
        return goTo(`/v2/appointment/${event.id}`, {
          initialValues: { appointment: event },
        })
      }
    }, 500)
  }

  return (
    <Wrapper
      theme={theme.palette}
      showTimeIndicator={showTimeIndicator}
      isMobile={isMobile}
    >
      <BigCalendar
        selectable
        components={components}
        step={1}
        timeslots={30}
        defaultView="day"
        views={['day']}
        resources={columns}
        localizer={localizer}
        events={events}
        dayLayoutAlgorithm="no-overlap"
        longPressThreshold={isMobile ? 3 : undefined} // allow onSelectSlot on mobile
        min={new Date(`${formattedDate} ${convertMinutesToHours(startHour)}`)}
        max={new Date(`${formattedDate} ${convertMinutesToHours(endHour)}`)}
        getNow={() => new Date(`${formattedDate} ${nowOnTimezone('hours')}`)}
        style={{ width: '100%' }}
        onSelectEvent={({ event }) => handleOpenEditEvent(event)}
        onSelectSlot={({ start, resourceId }) =>
          handleOpenCreateEvent(start, resourceId)
        }
      />
    </Wrapper>
  )
}

export default Calendar
