import styled from 'styled-components'

export const Content = styled.div`
  display: grid;
  grid-template-rows: repeat(5, 0.7fr);
  row-gap: 5px;
  width: 100%;
  height: 100%;
  align-items: center;
  padding: 10px;
  border-right: 1px solid ${({ theme }) => theme.primary.main};
  border-bottom: 1px solid ${({ theme }) => theme.primary.main};
  cursor: pointer;
  ${({ isToday, theme }) =>
    isToday ? `background: ${theme.calendar.main};` : ''}

  ${({ outOfBound }) =>
    outOfBound
      ? `
    & > * {
      opacity: 15%;
    }
  `
      : ''};
`

export const DayNumber = styled.div`
  grid-row: 1;
  ${({ isToday, theme }) =>
    isToday
      ? `
    color: ${theme.calendar.contrastText};
    width: 24px;
    text-align: center;
    text-decoration: underline;
    border-radius: 4px;
    padding-top: 2px;
  `
      : ''}
`

export const FulfillmentRateBar = styled.div`
  grid-row: 3 / span 3;
  position: relative;
  width: 100%;
  height: 100%;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: ${({ fulfillmentRate }) => fulfillmentRate}%;
    border-radius: 4px;
    background: ${({ color, bgImage }) => `${color} url("${bgImage}") repeat`};
    background-size: 142px;
    overflow: hidden;
    transition: 0.3s;
  }

  &:hover:after {
    -webkit-box-shadow: inset 0px 0px 44px 4px rgba(0, 0, 0, 0.07);
    box-shadow: inset 0px 0px 44px 4px rgba(0, 0, 0, 0.07);
  }
`

export const AppointmentCounts = styled.div`
  grid-row: 6;
  padding-left: ${({ closed }) => (closed ? '4px' : '0')};
`
