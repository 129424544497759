import React from 'react'
import { format } from 'date-fns'
import Typography from '@/v2/components/common/Typography'
import Button from '@/v2/components/common/Button'
import PatientAvatar from '@/v2/components/common/PatientAvatar'
import useModals from '@/v2/hooks/useModals'
import { usePatientAppointments } from '@/v2/hooks/patient/usePatientAppointments'
import { usePatientContext } from '@/v2/contexts/PatientContext'
import { FULL_DATETIME_FORMAT } from '@/v2/constants'
import { getDateTimeWithoutTimezone } from '@/v2/utils/convert'
import { AppointmentDetails, AppointmentWrapper, LinkWrapper } from './styles'

const NextAppointment = () => {
  const { goTo } = useModals()
  const { patient } = usePatientContext()
  const nextAppointment =
    patient?.nextAppointment ||
    (patient?.nextAppointments ? patient?.nextAppointments[0] : null)

  return (
    <AppointmentWrapper>
      <PatientAvatar patient={patient} />
      <AppointmentDetails>
        <Typography bold="true">{patient.fullName}</Typography>
        {nextAppointment && (
          <>
            <Typography fontSize="small">
              Next Appointment: {nextAppointment.appointmentType.title}
            </Typography>
            <Typography fontSize="small">
              {format(
                getDateTimeWithoutTimezone(nextAppointment.startsAt),
                FULL_DATETIME_FORMAT
              )}
            </Typography>
          </>
        )}
        {nextAppointment && (
          <LinkWrapper>
            <Button
              onClick={() => {
                goTo(`/v2/appointment/${nextAppointment.id}`, {
                  initialValues: { appointment: { ...nextAppointment } },
                })
              }}
              variant="text"
              color="link"
            >
              CHANGE
            </Button>
          </LinkWrapper>
        )}
      </AppointmentDetails>
    </AppointmentWrapper>
  )
}

export default NextAppointment
