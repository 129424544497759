import React from 'react'
import MuiSnackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import Slide from '@mui/material/Slide'

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Snackbar = ({
  open = false,
  message = 'Saved successfully',
  status = 'success',
  onClose,
}) => {
  const handleClose = (e, reason) => {
    if (reason === 'clickaway') return
    onClose()
  }

  return (
    <MuiSnackbar
      open={open}
      autoHideDuration={4000}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      TransitionComponent={props => <Slide {...props} direction="left" />}
      onClose={handleClose}>
      <Alert onClose={handleClose} severity={status} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </MuiSnackbar>
  )
}

export default Snackbar