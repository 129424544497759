import React, { useState } from 'react'
import styled from 'styled-components'
import IconButton from '@/v2/components/common/IconButton'
import Slider from '@mui/material/Slider'
import useDetectDevice from '@/v2/hooks/useDetectDevice'

const Wrapper = styled.div`
  display: inline-flex;
  width: ${({ show, ismobile }) =>
    show ? (ismobile ? '186px' : '200px') : ismobile ? '42px' : '32px'};
  transition: 0.3s;
  padding-right: 10px;
`

const SliderButton = ({
  title,
  icon,
  value,
  min = 0,
  max = 180,
  step = 1,
  onClick,
  onChange,
  ...otherProps
}) => {
  const { isMobile } = useDetectDevice()
  const [show, setShow] = useState(false)
  const handleChange = (e, newValue) => {
    onChange(newValue)
  }

  return (
    <Wrapper
      show={show}
      ismobile={isMobile ? 1 : 0}
      onMouseEnter={() => !isMobile && setShow(true)}
      onMouseLeave={() => !isMobile && setShow(false)}
    >
      <IconButton
        title={title}
        onClick={() => {
          onClick()
          if (isMobile) {
            setShow(!show)
          }
        }}
      >
        {icon}
      </IconButton>
      {show && (
        <Slider
          size="small"
          step={step}
          value={value}
          valueLabelDisplay="auto"
          color="secondary"
          min={min}
          max={max}
          sx={{ ml: '15px' }}
          onChange={handleChange}
          {...otherProps}
        />
      )}
    </Wrapper>
  )
}

export default SliderButton
