import React from 'react'
import Modal from '@/v2/components/common/Modal'
import AppointmentInformation from './AppointmentInformation'
import Patient from './Patient'
import { Wrapper } from './styles'
import useAppointment from '@/v2/hooks/useAppointment'
import { Skeleton } from '@mui/material'

const Details = ({ isOpen, onClose, onEdit, onCancel, ...props }) => {
  const { id } = props.appointment
  const { appointment, loading } = useAppointment({
    appointmentId: id,
  })

  return (
    <Modal
      isOpen={isOpen}
      showClose={false}
      showConfirm={false}
      onConfirm={() => {}}
      onClose={onClose}
      title="Appointment info"
      maxWidth={false}
    >
      {loading ? (
        <Wrapper>
          <Skeleton variant="rectangular" width={295} height={300} />
          <Skeleton variant="rectangular" width={295} height={300} />
        </Wrapper>
      ) : (
        <Wrapper>
          <AppointmentInformation
            appointment={appointment}
            onEdit={onEdit}
            onCancel={onCancel}
          />
          <Patient appointment={appointment} />
        </Wrapper>
      )}
    </Modal>
  )
}

export default Details
